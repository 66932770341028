<template>
  <h2 class="font-extrabold text-3xl text-center mb-10 mt-4">Online Rewriting and translation tool</h2>
 
  <!-- ITEM 1 STARTS -->
  <div class="w-full flex flex-col md:px-20 lg:px-20 px-5 my-10">
    <div>
            <label for="input_text" class="block mb-2 text-sm font-medium text-gray-900">Enter Input Text To Rewrite</label>
            <textarea type="text" v-model="initialText" id="input_text" maxlength="15000" class="bg-gray-50 border border-gray-300 text-violet-500 font-semibold text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5" placeholder="Enter Input Text To Rewrite Here" rows="6" required ></textarea>
    </div>


     <div v-if="isFirstButtonLoading" class="flex justify-center mt-4">
  <div  role="status">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin  fill-violet-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
</div>
    <button v-else @click="rewriteText" class="bg-violet-500 mt-2 py-2 rounded-lg font-semibold text-white">Rewrite Text</button>
  </div>
  <!-- ITEM 1 ENDS -->

 
  <!-- ITEM 1 STARTS -->
  <div class="w-full flex flex-col md:px-20 lg:px-20 px-5 my-10">
    <div>
            <label for="input_text" class="block mb-2 text-sm font-medium text-gray-900">Enter Input Text To Translate</label>
            <textarea type="text" v-model="rewrittenText" id="input_text" class="bg-gray-50 border border-gray-300 text-violet-500 font-semibold text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5" placeholder="Enter Input Text To Translate" rows="6" required ></textarea>
    </div>

    <div v-if="isSecondButtonLoading" class="flex justify-center mt-4">
  <div  role="status">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin  fill-violet-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
</div>



    <button v-else @click="translateText" :disabled="!rewrittenText" :class="rewrittenText?'bg-violet-500 mt-2 py-2 rounded-lg font-semibold text-white':'bg-gray-200 mt-2 py-2 rounded-lg font-semibold text-gray-500'">Translate Text</button>
  </div>
  <!-- ITEM 1 ENDS -->



 
  <!-- ITEM 1 STARTS -->
  <div class="w-full flex flex-col md:px-20 lg:px-20 px-5 my-10">
    <div>
            <label for="input_text" class="block mb-2 text-sm font-medium text-gray-900">Enter Input Text To Generate Voice</label>
            <textarea :style="!translatedText?'':'font-family: \'Tajawal\', sans-serif;'" type="text" v-model="translatedText" id="input_text" :class="!translatedText?'bg-gray-50 border border-gray-300 text-violet-500 font-semibold text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5':'text-right text-lg bg-gray-50 border border-gray-300 text-violet-500 font-semibold text-sm rounded-lg focus:ring-violet-500 focus:border-violet-500 block w-full p-2.5'" placeholder="Enter Input Text To Generate Voice Here" rows="6" required ></textarea>
    </div>

    <div v-if="isThirdButtonLoading" class="flex justify-center mt-4">
  <div  role="status">
    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin  fill-violet-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
</div>




    <button v-else @click="generateVoice" :disabled="!translatedText" :class="translatedText?'bg-violet-500 mt-2 py-2 rounded-lg font-semibold text-white':'bg-gray-200 mt-2 py-2 rounded-lg font-semibold text-gray-500'">Generate Voice</button>
  </div>
  <!-- ITEM 1 ENDS -->



</template>

<script>
import axios from 'axios';
export default {
  data(){
    return{
      initialText: null,
      rewrittenText: null,
      translatedText: null, 
      apiKey: "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",
      isFirstButtonLoading: false,  
      isSecondButtonLoading: false,  
      isThirdButtonLoading: false,  
    }
  },
  methods:{
    rewriteText(){
      this.isFirstButtonLoading = true;
      axios
       // The URL of the endpoint
      const url = "https://api.openai.com/v1/chat/completions";

      // The data to be sent in the POST request
      const postData = {
          "model": "gpt-4-turbo-preview",
          "messages": [
              {
                  "role": "user",
                  "content": "please rewrite this text - " + this.initialText
              }
          ],
          "temperature": 1,
          "max_tokens": 4096,
          "top_p": 1,
          "frequency_penalty": 0,
          "presence_penalty": 0
      };

      // Set the headers
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.apiKey
      };

      // Send POST request using Axios
      axios.post(url, postData, { headers })
          .then(response => {
              // Handle response data here
              // console.log(response.data);
              this.isFirstButtonLoading = false;
              this.rewrittenText = response.data.choices[0].message.content;
          })
          .catch(error => {
              // Handle errors here
              console.error('Error:', error);
          });

    },



    translateText(){
      this.isSecondButtonLoading = true;
      axios
       // The URL of the endpoint
      const url = "https://api.openai.com/v1/chat/completions";

      // The data to be sent in the POST request
      const postData = {
          "model": "gpt-4-turbo-preview",
          "messages": [
              {
                  "role": "user",
                  "content": "please translate this text to arabic - " + this.rewrittenText
              }
          ],
          "temperature": 1,
          "max_tokens": 4096,
          "top_p": 1,
          "frequency_penalty": 0,
          "presence_penalty": 0
      };

      // Set the headers
      const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.apiKey
      };

      // Send POST request using Axios
      axios.post(url, postData, { headers })
          .then(response => {
              // Handle response data here
              // console.log(response.data);
              this.isSecondButtonLoading = false;
              this.translatedText = response.data.choices[0].message.content;
          })
          .catch(error => {
              // Handle errors here
              console.error('Error:', error);
          });

    },


    async generateVoice() {
      this.isThirdButtonLoading = true;
      // Setup the URL and payload
  
      const payload = {
        "model_id": "eleven_multilingual_v2",
        "text": this.translatedText,
        "voice_settings": {
          "similarity_boost": 0.5,
          "stability": 0.5,
          "style": 0
        }
      };

      // Set the headers
      const headers = {
        'Content-Type': 'application/json',
        'xi-api-key': 'f10277bb39904a4d42d6375b816b35de',
        'accept': 'audio/mpeg'
      };

      try {
        // Make POST request using Axios
        const response = await axios.post("https://api.elevenlabs.io/v1/text-to-speech/jUiVn2MDyA0gSOeIfPef", payload, { headers, responseType: 'arraybuffer' });

        // Save the audio file
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'output.mp3';
        link.click();
        this.isThirdButtonLoading = false;
      } catch (error) {
        // Handle errors here
        console.error('Error:', error);
      }
    }
  },



}
</script>
